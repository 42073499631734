
import React, { useEffect, useState } from "react";
import ProductSwap2 from "../../Component/ProductSlider/ProductSwap2";
import ProductSwap3 from "../../Component/ProductSlider/ProductSwap3";
import { Link } from "react-router-dom";
import ProductSwap from "../../Component/ProductSlider/Productswap";
import ProductSwap4 from "../../Component/ProductSlider/Productswap4";
import ProductCart4 from "../../Component/Card/ProductCart4";
import SimilerProductSection1 from "../../Component/Sections/SimilerProductSection1";
// import ProductSwap from "../Component/ProductSlider/Productswap";
import { useLocation } from 'react-router-dom';
import Order from "../../../API/API_SERVISES/Order.js";


const ProductDetailPage2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { item } = location.state;

  const [activeComponent, setActiveComponent] = useState(<ProductSwap />);
  const [description, setDescription] = useState(false);
  const [features, setFeatures] = useState(false);
  const [specification, setSpecification] = useState(false);
  const [review, setReview] = useState(false);

  const opendesc1 = () => {
    setDescription(!description);

  };
  const opendesc2 = () => {
    setFeatures(!features);

  };
  const opendesc3 = () => {
    setSpecification(!specification);

  };

  const opendesc4 = () => {
    setReview(!review)
  };

  const showComponent = (component) => {
    setActiveComponent(component);
  };
  const [inputValues, setInputValues] = useState({
    d1ItemId: item.d1ItemId,
    d2ItemId: item.d2ItemId,
    d1AccountId: 214,
    d2AccountId: 214,
    userId: 1,
    quantity: 1,
    unit: "Pcs",
    itemPrice: item.itemPrice,
  });

  const AddToCart = async () => {
    console.log(item)
    alert(item)
    const data = {
      d1ItemId: item.d1Id,
      d2ItemId: item.d2Id,
      d1AccountId: 214,
      d2AccountId: 214,
      userId: 1,
      quantity: 1,
      unit: "Pcs",
      itemPrice: item.salePrice,
    }
    // const isSuccess = Order.insertCreateEcomCartDetail(data);
    // setInputValues(isSuccess)
    try {
      const val = await Order.insertCreateEcomCartDetail(data);
      if (Boolean(val) === true) {
        alert("Delete data successfully", "Ok");
        // fetchData()
      } else {
        alert("Error Deleting Data");
      }
      // fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }

    setInputValues({
      d1ItemId: " ",
      d2ItemId: " ",
      d1AccountId: " ",
      d2AccountId: " ",
      userId: " ",
      quantity: " ",
      unit: " ",
      itemPrice: " ",
    });

  }


  return (
    <div>
      <div className="ProductDetailPage">
        <div className="ProductDetailPage-left-c2">
          <div className="ProductDetailPage-imagebox1-2">
            <div onClick={() => showComponent(<ProductSwap />)} className="ProductDetailPage-smallimg-c2">
              <img
                className="ProductDetailPage-image"
                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1832_BLACKRED_914_1.jpg"
                alt=""
              />
            </div>
            <div onClick={() => showComponent(<ProductSwap2 />)} className="ProductDetailPage-smallimg-c2">
              <img
                className="ProductDetailPage-image"
                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1832_BLACKRED_914_1.jpg"
                alt=""
              />
            </div>
            <div onClick={() => showComponent(<ProductSwap3 />)} className="ProductDetailPage-smallimg-c2">
              <img
                className="ProductDetailPage-image"
                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1832_BLACKRED_914_1.jpg"
                alt=""
              />
            </div>

            <div onClick={() => showComponent(<ProductSwap4 />)} className="ProductDetailPage-smallimg-c2">
              <img
                className="ProductDetailPage-image"
                src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1832_BLACKRED_914_1.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="ProductDetailPage-imagebox2-c2">
            {activeComponent}
          </div>
        </div>
        <div className="ProductDetailPage-right-c2">
          <div className="productdetailbox">
            <p className="productdetail-name font-2">
              {item.name}
            </p>
            <p className="productdetail-name">
              <div className="product-detail-name">
                4.7 <i className="fa-solid fa-star product-detail-star"></i> 1507
              </div>
            </p>
            <div
              className="productdetail-name"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p className="product-detail-price">
                <i className="fa-solid fa-indian-rupee-sign"></i> {item.saleprice}
              </p>
              <p className="product-detail-price-2">
                <i className="fa-solid fa-indian-rupee-sign"></i> {item.saleprice * 2}
              </p>
            </div>
            <p className="productdetail-name">
              <div>
                <p className="product-detail-desc">
                  Black shade glasses and one of the best glasses of our store
                  and Black shade glasses and one of the best glasses of our
                  store
                </p>
              </div>
              <div className="color-section-pd">
                <p style={{ marginBottom: "0" }}>Color</p>
                <div className="color-option-pd">
                  <div className="outline-coloroption">
                    <div className="color-option-box1"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box2"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box3"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box4"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box5"></div>
                  </div>
                </div>
              </div>
            </p>
            <div className="productdetail-bottom-box">
              <div className="product-detail-button-box">
                <div onClick={AddToCart} className="product-detail-button">
                  Buy Frame Only{" "}
                </div>
                <Link to="/buywithlens" className="product-detail-button">
                  Buy With Lens
                </Link>
              </div>
              <div className="productdetail-name">
                <p className="product-detail-return">
                  Return Policy : 7 day easy return policy
                </p>
                <p className="product-detail-enquiry">Product Enquiry</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ProductDetailPage-bottom">
        <div className="ProductDetailPage-bottom-1">
          <div onClick={opendesc1} className="accordion-box">
            Description
            {description ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {description && (
            <div className="Description-accordian-box">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
                laborum, nemo pariatur minima minus totam sit accusantium
                impedit sunt mollitia repellat adipisci dolorum iure
                exercitationem nihil, libero neque recusandae consequatur
                numquam repellendus. Quibusdam alias placeat animi labore dolor
                corporis delectus nemo nam quas provident sint sequi, eos,
                deleniti, perferendis sed.
              </p>
            </div>
          )}
          <div onClick={opendesc2} className="accordion-box">
            Features
            {features ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {features && <div className="Features-accordian-box">
            <table>
              <tr className="dark-tablerow">
                <td>  Frame Material</td>
                <td>Metal</td>
              </tr>
              <tr className="light-tablerow">
                <td>   Lens Material</td>
                <td>Polycarbonate</td>
              </tr>
              <tr className="dark-tablerow">
                <td>Frame Color</td>
                <td>Black</td>
              </tr>
              <tr className="light-tablerow">
                <td>Shape</td>
                <td>Square</td>
              </tr>
              <tr className="dark-tablerow">
                <td>Brand</td>
                <td>KILLER</td>
              </tr>
              <tr className="light-tablerow">
                <td>Gender</td>
                <td>Men, Women</td>
              </tr>
              <tr className="dark-tablerow">
                <td>Dimensions</td>
                <td>48-20-140</td>
              </tr>
              <tr className="light-tablerow">
                <td>SKUID</td>
                <td>E12B10286</td>
              </tr>
              <tr className="dark-tablerow">
                <td>Product type</td>
                <td>eyeglasses</td>
              </tr>
              <tr className="light-tablerow">
                <td>Country of Origin</td>
                <td>India</td>
              </tr>
              <tr className="dark-tablerow">
                <td>Manufactured by</td>
                <td>Scavin International Exim Pvt. Ltd.</td>
              </tr>
              <tr className="light-tablerow">
                <td>Sold by</td>
                <td>EyeMyEye Pvt Ltd</td>
              </tr>
              <tr className="dark-tablerow">
                <td>Raw Material Sourced from</td>
                <td>	India </td>
              </tr>
            </table>
          </div>}
          <div onClick={opendesc3} className="accordion-box">
            Specification
            {specification ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {specification && (
            <div className="Specification-accordian-box">
              <p>✔ Full Frame</p>
              <p>✔ Light Weight and Comfortable</p>
              <p>✔ Strong and Durable</p>
              <p>✔ Free Eyeglasses Case with Cleaning Cloth</p>
            </div>
          )}


          <div style={{width:"80%"}} className="Success"><span>fdgh</span></div>



          <div onClick={opendesc4} className="accordion-box" style={{ borderBottom: "0" }}>
            Review & Rating
            {review ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {review && (
            <div className="Specification-accordian-box">

            </div>
          )}
        </div>
      </div>


      {/* <SimilerProductSection1 heading="Similer Products" productComponent={<ProductCart4 />} /> */}
    </div>
  );
};

export default ProductDetailPage2;
