import React, { useContext, useState } from "react";
// import "./App.css";
import { Link, useNavigate } from "react-router-dom";
import appoint from "../../Assets/Image_lc/eye bookappoint.webp";

const Appointment = ({ closeNavbarLc }) => {


  const handleButtonClick = () => {
    closeNavbarLc()
  };

  return (
    <>

      <div style={{ margin: 0, boxSizing: "border-box" }}>
        <div style={{ display: "flex" }} className={`book-appointment-div`}>

          <img
            className={`left-img-div`}
            src={appoint}
          />


          <div className={`div-for-text-content`}>
            <h1 className={`h1-for-text-content`}>
              Get your eyes checked at home
            </h1>

            <p className={`p-for-content`}>
              A certified refractionist will visit you with latest eye testing
              machines & 100 trial frames
            </p>
            {/* <Link to={"/bookappointment"}>
              <button

                onClick={handleButtonClick}
                className={`btn-book`}
              >
                Book appointment
              </button>
            </Link> */}
            <button
                onClick={handleButtonClick}
                className={`btn-book`}
              >
                Coming Soon
              </button>
          </div>
        </div>
      </div>

    </>
  );
};

export default Appointment;
