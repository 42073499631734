import React, { useContext, useEffect, useState } from "react";
import styletype from "../../Assets/Icons/glasses (1).png";
import styletype1 from "../../Assets/Icons/glasses.png";
import styletype2 from "../../Assets/Icons/hexa.png";
import styletype3 from "../../Assets/Icons/oval-shape-reading-eyeglasses.png";
import styletype4 from "../../Assets/Icons/Square.webp";
import styletype5 from "../../Assets/Icons/Wayfarer.webp";
import styletype6 from "../../Assets/Icons/Rectangle.webp";
import styletype7 from "../../Assets/Icons/sun-glasses.png";
import styletype8 from "../../Assets/Icons/Geometric.webp";
import styletype9 from "../../Assets/Icons/Clubmaster.webp";
import styletype10 from "../../Assets/Icons/Sports.webp";
import { useSpring, animated } from "react-spring";
import MultiRangeSlider from "../../Pages/ProductSearchPage/MultiRangeSlider";
import ItemMasterApi from "../../../API/API_SERVISES/ItemMaster";
import MyContext from "../../MyContext";
import { useLocation } from 'react-router-dom';


const MobileFilterPage = (props) => {

  const { productSearch, setProductSearch, searchName, setSearchName, changeProductGrid, changegrid, setChangegrid, setClose, productlist, setProductList,
    attributeList, setAttributeList, imageBaseUrl, currentUser, shopBanner, companyData, } = useContext(MyContext)

  const [selectedOption, setSelectedOption] = useState("prize-range");
  const [price, setPrice] = useState(false);
  const [maxSalePrice, setMaxSalePrice] = useState(0);
  const [shopFor, setShopFor] = useState(false);
  const [color, setColor] = useState(false);
  const [style, setStyle] = useState(false);
  const [shape, setShape] = useState(true);
  const [size, setSize] = useState(false);
  const [material, setMaterial] = useState(false);
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [activeAttrId, setActiveAttrId] = useState([]);
  const [childAttrId, setChildAttrId] = useState(null);
  const [filteredata2, setFilteredData2] = useState([]);
  const [filterProducts, setFilterProducts] = useState(false);


  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  const location = useLocation();
  const query = useQuery();
  const productId = query.get('productId');
  const searchValueData = location?.state?.searchValue || "";


  useEffect(() => {
    if (productId) {
      fetchData2(productId);
    }
  }, [productId]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    setMaxVal(maxSalePrice);
  }, [maxSalePrice]);


  //******************* Filter ************************* */

  const fetchDataFilter = async () => {
    setLoading(true);
    if (maxVal !== 0) {
      const filteredata = [
        {
          fieldName: "salePrice",
          operatorName: "lessthan",
          compareValue: maxVal
        },
        {
          fieldName: "salePrice",
          operatorName: "greaterthan",
          compareValue: minVal
        },
      ]
      try {
        const categoryName = await ItemMasterApi.GetItemMasterFilter(filteredata);
        console.log(categoryName);
        setProductList(categoryName)
        // setMaxSalePrice(0)
        setLoading(false);
        console.log("Search", searchName)
      } catch (error) {
        setLoading(false);
        console.error("Error Fetching Data:", error);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    if (productId != null) {

      fetchDataFilter()
    }
  }, [minVal, maxVal, searchName]);

  const fetchData2 = async () => {
    setLoading(true)
    try {
      const val = await ItemMasterApi.GetEcomItemFilterFromCategory(productId);
      console.log("This is Ecom", val)
      setAttributeList(val);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    const maxfunc = async () => {
      if (productlist && productlist?.length > 0) {
        const maxPrice = await productlist.reduce(
          (max, product) => Math.max(max, product.salePrice),
          -Infinity
        );
        setMaxSalePrice(maxPrice + 50);
      }
    }
    maxfunc()
  }, [productId]);

  const [visibleItems, setVisibleItems] = useState(4);
  const defaultVisibleCount = 10;

  // const toggleOption = (attributeName) => {
  //   setVisibleItems(prevState => ({
  //     ...prevState,
  //     [attributeName]: prevState[attributeName] === defaultVisibleCount ? null : defaultVisibleCount
  //   }));
  // };


  const toggleOption = (attributeName) => {
    setVisibleItems(prevState => {
      // Hide all attributes except the clicked one
      const newVisibleItems = { ...prevState };
      Object.keys(newVisibleItems).forEach(attr => {
        if (attr !== attributeName) {
          newVisibleItems[attr] = null; // Hide other lists
        }
      });
      
      // Toggle visibility for the clicked attribute
      newVisibleItems[attributeName] = newVisibleItems[attributeName] === defaultVisibleCount ? null : defaultVisibleCount;
      return newVisibleItems;
    });
  };

  const handleMoreClick = (attributeName) => {
    setVisibleItems(prevState => ({
      ...prevState,
      [attributeName]: prevState[attributeName] + defaultVisibleCount
    }));
  };

  const handleHideClick = (attributeName) => {
    setVisibleItems(prevState => ({
      ...prevState,
      [attributeName]: defaultVisibleCount
    }));
  };

  const handleParentClick = (parentId) => {
    setParentId(parentId);
    console.log("Parent ID:", parentId);

  };


  const handleChildClick = async (attrId) => {
    setLoading(true);
    setActiveAttrId((prevActiveAttrIds) => {
      if (prevActiveAttrIds.includes(attrId)) {
        return prevActiveAttrIds.filter(id => id !== attrId);
      } else {
        return [...prevActiveAttrIds, attrId];
      }
    });

    setChildAttrId(attrId);
    console.log("Child Attr ID:", attrId);

    let updatedValues;

    if (activeAttrId.includes(attrId)) {
      // If !active, remove the attribute
      updatedValues = activeAttrId.filter(id => id !== attrId).map(id => ({
        parentId,
        attrId: id,
      }));
    } else {
      // If active, add the attribute
      updatedValues = [
        ...activeAttrId.map(id => ({
          parentId,
          attrId: id,
        })),
        {
          parentId,
          attrId,
        }
      ];
    }

    const filteredata1 = [
      {
        fieldName: "string",
        operatorName: "string",
        compareValue: "string"
      }
    ];

    try {
      const filteredata = {
        filter: filteredata1,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        attribute: updatedValues,
        pageNumber: 0,
        pageSize: 50000
      };

      console.log(filteredata);

      const response = await ItemMasterApi.GetItemMasterFilterAttribute(filteredata);
      if (response) {
        console.log(response);
        setProductList(response);
      }
    } catch (error) {
      console.error('Error fetching filtered data:', error);
    } finally {
      setLoading(false);
    }
  };

  // ************************ Filter Product on the click of attribute list ****************

  useEffect(() => {
    const clearStorageOnRefresh = () => {
      localStorage.removeItem("attriButeName");
    };

    window.addEventListener("beforeunload", clearStorageOnRefresh);

    return () => {
      window.removeEventListener("beforeunload", clearStorageOnRefresh);
    };
  }, []);

  useEffect(() => {
    const fetchData = () => {
      const attrName = localStorage.getItem("attriButeName");
      if (attrName) {
        try {
          const parsedData = JSON.parse(attrName);
          if (Array.isArray(parsedData)) {
            parsedData.forEach((item) => {
              handleCheckboxChange12(item.attrId, item.parentId, true, false);
            });
          }
        } catch (error) {
          console.error("Error parsing localStorage data:", error);
        }
      }
    };
    fetchData();
  }, []);

  const handleCheckboxChange12 = async (attrId, attributeId, checked, isFromUseEffect) => {
    setLoading(true);
    let updatedValues;

    await setFilteredData2((prevFilteredData2) => {
      if (checked) {
        updatedValues = [
          ...prevFilteredData2,
          { parentId: attributeId, attrId: attrId },
        ];
      } else {
        updatedValues = prevFilteredData2.filter(
          (filter) => !(filter.parentId === attributeId && filter.attrId === attrId)
        );
      }
      if (isFromUseEffect) {
        localStorage.setItem("attriButeName", JSON.stringify(updatedValues));
      }
      return updatedValues;
    });

    const filterDataPayload = {
      filter: [
        {
          fieldName: "string",
          operatorName: "string",
          compareValue: "string"
        }
      ],
      order: [
        {
          propertyName: "string",
          ascending: true
        }
      ],
      attribute: updatedValues,
      pageNumber: 0,
      pageSize: 50000
    };
    try {
      console.log(filterDataPayload);
      const categoryName = await ItemMasterApi.GetItemMasterFilterAttribute(filterDataPayload);
      setFilterProducts(categoryName);
      setProductList(categoryName);
    } catch (error) {
      console.error("Error Fetching Data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fadeAnimation = useSpring({
    opacity: price ? 1 : 0,
    height: price ? 80 : 0,
    config: { duration: 200 },
  });

  const renderSelectedOption = () => {
    switch (selectedOption) {
      case "prize-range":
        return (
          <div className="filter-options">
            {" "}
            {/* <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>
                0 to 500 <i className="fa-solid fa-indian-rupee-sign"></i>
              </p>
            </div> */}
            {/* {price && ( */}
            <animated.div style={fadeAnimation} className="filter-line">
              {" "}
              <MultiRangeSlider
                min={0}
                max={maxSalePrice}
                handleCheckboxChange
                minVal={minVal}
                setMinVal={setMinVal}
                maxVal={maxVal}
                setMaxVal={setMaxVal}
                onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
              />
            </animated.div>

            {Array.isArray(attributeList) && attributeList.map(attribute => (
              <React.Fragment key={attribute.attributeName}>
                {/* <div className="filter-heading" onClick={() => { toggleOption(attribute.attributeName); handleParentClick(attribute.attributeId); }}>
                  {attribute.attributeName}
                  {visibleItems[attribute.attributeName] ? (
                    <i className="fa-solid fa-caret-up option-icon1"></i>
                  ) : (
                    <i className="fa-solid fa-sort-down option-icon"></i>
                  )}
                </div> */}
                {/* <div className="filter-options"> */}
                <div className="filter-scroll-theme2">
                  {visibleItems[attribute.attributeName] && (
                    <React.Fragment>
                      {/* Items with images */}
                      <div className="filter-options">
                        <div className="filter-line">
                          <animated.div className="filter-options">
                            <div className="style-filter-box-wrap">
                              {attribute.attributeChildren
                                .filter(child => child.imageId)
                                .slice(0, visibleItems[attribute.attributeName])
                                .map(child => (
                                  <div key={child.attrId} style={{ margin: "2px" }} className={`style-filter-1 ${activeAttrId.includes(child.attrId) ? 'style-filter-1-active' : 'style-filter-1'}`}
                                    onClick={() => handleChildClick(child.attrId)}>
                                    <div
                                      className="filter-type-image"
                                      style={{
                                        backgroundImage: child.imageId ? `url(${imageBaseUrl}${child.imageId})` : 'none',
                                        height: '40px',
                                        backgroundSize: '85%',
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                      }}
                                    />
                                    <p style={{ marginTop: "1px" }}>{child.attrName}</p>
                                  </div>
                                ))}
                            </div>
                          </animated.div>
                        </div>
                      </div>

                      {/* Items without images */}
                      {attribute.attributeChildren
                        .filter(child => !child.imageId)
                        .slice(0, visibleItems[attribute.attributeName])
                        .map(child => {
                          const isChecked = filteredata2.some(
                            filter => filter.parentId === attribute.attributeId && filter.attrId === child.attrId
                          );

                          return (
                            <div key={`${child.attrId}-${attribute.attributeId}`} className="filter-options">
                              <div className="filter-line">
                                <input
                                  type="checkbox"
                                  className="ui-checkbox"
                                  value={`${child.attrId},${attribute.attributeId}`}
                                  checked={isChecked}
                                  onChange={() => handleCheckboxChange12(child.attrId, attribute.attributeId, !isChecked, true)}
                                />
                                {attribute.attributeName.toLowerCase() === 'color' && (
                                  <div style={{ background: child.attrName }} className="filterclrset"></div>
                                )}
                                <p style={{ margin: "0" }}>{child.attrName}</p>
                              </div>
                            </div>
                          );
                        })}


                      {/* "More" and "Hide" buttons */}
                      {attribute.attributeChildren.length > visibleItems[attribute.attributeName] && (
                        <p
                          style={{ textAlign: "center", color: "$Theme-color", cursor: "pointer" }}
                          onClick={() => handleMoreClick(attribute.attributeName)}
                        >
                          {attribute.attributeChildren.length - visibleItems[attribute.attributeName]} More
                        </p>
                      )}
                      {visibleItems[attribute.attributeName] > defaultVisibleCount && (
                        <p
                          style={{ textAlign: "center", color: "$Theme-color", cursor: "pointer" }}
                          onClick={() => handleHideClick(attribute.attributeName)}
                        >
                          Hide
                        </p>
                      )}
                    </React.Fragment>
                  )}
                </div>
                {/* </div> */}
              </React.Fragment>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mobile-filter-page">
      <i
        onClick={props.close}
        className="filter-back-button fa-solid fa-arrow-left-long"
      ></i>
      <div className="mobile-filter-page-top">
        Filter <i style={{ marginLeft: "15px" }} className="fa-solid fa-filter"></i>
      </div>
      <div className="mobile-filter-page-main">
        <div className="mobile-filter-page-left">
          <div
            className={`MobileFilter-options ${selectedOption === "prize-range" ? "mobile-filter-active" : ""
              }`}
            onClick={() => handleOptionClick("prize-range")}
          >
            {" "}
            Price-Range
          </div>
          {Array.isArray(attributeList) && attributeList.map(attribute => (
            <React.Fragment key={attribute.attributeName}>
              <div className={`MobileFilter-options ${selectedOption === "Colour" ? "mobile-filter-active" : ""
                }`} onClick={() => { toggleOption(attribute.attributeName); handleParentClick(attribute.attributeId); }}>
                {attribute.attributeName}
                {/* {visibleItems[attribute.attributeName] } */}
                {visibleItems[attribute.attributeName] && visibleItems[attribute.attributeName] > 0 && (
                  <span>({visibleItems[attribute.attributeName]})</span>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="mobile-filter-page-right">{renderSelectedOption()}</div>
      </div>
      <div className="Button-box-filter">
        <button onClick={props.close} className="Button-1-filter">
          Cancel
        </button>
        <button className="Button-2-filter" onClick={props.close} >Add Filter</button>
      </div>
    </div>
  );
};

export default MobileFilterPage;
