
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import ContentLense1 from "../../Assets/Image_lc/ContectLenses/aqualens1.webp";
import ContentLense2 from "../../Assets/Image_lc/ContectLenses/aqualens2.webp";
import ContentLense3 from "../../Assets/Image_lc/ContectLenses/aqualens3.webp";
import ContentLense4 from "../../Assets/Image_lc/ContectLenses/aqualens4.webp";
import ContentLense5 from "../../Assets/Image_lc/ContectLenses/aqualens5.webp";


const CarouselLensLc = (props) => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true
  };


  return (
    <div>
      <div>
        <h1 className="product-slider-heading" >{props.heading}</h1>
        <Slider className="product-slider-2" {...settings}>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
         <Link to="/productsearch"> <img src={ContentLense1} alt="" /></Link>
        </Slider>
      </div>
    </div>
  );
};

export default CarouselLensLc;

