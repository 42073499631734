import React, { useEffect, useState } from "react";
import WebTemplate from "../../API/API_SERVISES/WebTemplate";
import { useParams } from "react-router-dom";
import TemplateSetting from "../../API/API_SERVISES/TemplateSetting";
// import jsPDF from "jspdf";
import "jspdf-autotable";
import html2pdf from "html2pdf.js";
import Loader1 from "./Loaders/Loader1";

const ReportDesignForA4 = (props) => {
  const [apiData, setApiData] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [styleArray, setStylesArray] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("styleArray", styleArray);
    console.log("apiData", apiData);
    console.log("droppedItems", droppedItems);
  }, [apiData, droppedItems, styleArray]);

  const [variableReplaceData, setVariableReplaceData] = useState("");

  const showTemplateData = async (ord1Id, templateName) => {
    try {
      setLoading(true);
      const filteredData = [
        {
          fieldName: "name",
          operatorName: "equal",
          compareValue:
            templateName ||
            props.templateName ||
            "FinalBillPrint" ||
            "TestReport" ||
            "ReportDesign" ||
            "TestReport",
        },
      ];
      var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(
        filteredData
      );
      if (getdata) {
        if (getdata.jsonStructure) {
          try {
            const decodedData = decodeURIComponent(getdata.jsonStructure);
            const parsedData = JSON.parse(decodedData);
            console.log("parsedData1", parsedData);
            // getdata.jsonStructure = parsedData
            setDroppedItems(parsedData);
            // if (getdata.textContents) {
            // const decodedData = decodeURIComponent(getdata.textContents)
            // const parsedData = JSON.parse(decodedData);
            // console.log("parsedData2", parsedData);
            // getdata.jsonStructure = parsedData

            //setStylesArray(parsedData);
            // }
            console.log("ord1Id", props.ord1Id);
            const orderNo = ord1Id || props.ord1Id || 0;
            let sqlQuery = getdata?.insertQuery;
            if (sqlQuery?.includes("@transId")) {
              sqlQuery = sqlQuery.replace(/@transId/g, orderNo);
            }
            const queryData = {
              query1: sqlQuery, //"select ord1Id, itemName, OrderNo,ServiceTypeId,TableGroupId,TableId,PaymentStatus,ord2.itemName,D2ItemId,Qty,Rate,ord2.TotalAmt,KotStatus from ord2 inner Join ord1 on ord1.id = ord2.ord1id where o2orderno = 15",
              query2: sqlQuery,
              query3: sqlQuery,
            };
            let tableData;
            if (sqlQuery) {
              tableData = await TemplateSetting.getMultiDatasetExecuteQuery(
                queryData
              );
              setVariableReplaceData(tableData);
            }
            if (getdata.textContents) {
              const decodedData = decodeURIComponent(getdata.textContents);
              const parsedData = JSON.parse(decodedData);
              console.log("parsedData2", parsedData);
              // getdata.jsonStructure = parsedData
              return { printData: parsedData, replaceData: tableData };
              // setStylesArray(parsedData);
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
            setDroppedItems([]);
          } finally {
            setLoading(false);
          }
        } else {
          setDroppedItems([]);
        }
        var templateInnerHtml = getdata.textContents;
        var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
        getdata.textContents = templateInnerHtmlDecode;

        setApiData(getdata);
        setLoading(false);
        // setDataHtml(getdata)
      } else {
        //document.getElementById("drop-target-innerHtml").outerHTML = "";
        setDroppedItems([]);
        setLoading(false);
        return { printData: null, replaceData: null };
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  function replaceVariables1(itemContent, variableReplaceData) {
    for (const tableName in variableReplaceData) {
      const tableData = variableReplaceData[tableName];

      // If there's no data in the table, continue to the next table
      if (!Array.isArray(tableData) || tableData.length === 0) {
        continue;
      }

      // Loop through each key-value pair in the tableData (we consider only the first item for now)
      const obj = tableData[0];

      // Replace placeholders in the content with actual values from the table data
      for (const key in obj) {
        const placeholder = `${key}`;
        const value = obj[key];

        const placeholderRegex = new RegExp(placeholder, "g");
        itemContent = itemContent.replace(placeholderRegex, value);
      }
    }
    return itemContent;
  }

  useEffect(() => {
    // showTemplateData();
    const fetchData = async () => {
      const htmlRegex =
        /<([a-z][\w0-9]*)\s*[^>]*>.*<\/\1\s*>|<([a-z][\w0-9]*)\s*[^>]*\/>/i;
      const { printData, replaceData } = await showTemplateData();
      if (printData == null) {
        console.error("No Template Data");
        return;
      }
      let replacedData = [];
      for (const item of printData) {
        const { content, style } = item;
        if (htmlRegex.test(content)) {
          const updatedContent = await replaceVariables(content, replaceData);
          replacedData.push({ content: updatedContent, style });
        } else {
          const updatedContent = await replaceVariables1(content, replaceData);
          replacedData.push({ content: updatedContent || "", style });
        }
      }
      // setStylesArray(printData);
      console.log(printData);
      setStylesArray(replacedData);
      console.log("styData", printData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Use a for loop to iterate over stylesArray
    for (let index = 0; index < styleArray.length; index++) {
      const styleObject = styleArray[index];
      console.log(styleObject.id);

      const element = document.getElementById(styleObject.id);

      if (element) {
        for (const [key, value] of Object.entries(styleObject.style)) {
          // Convert kebab-case to camelCase for inline styles
          const camelCaseKey = key.replace(/-([a-z])/g, (g) =>
            g[1].toUpperCase()
          );
          element.style[camelCaseKey] = value;
        }
      }
    }
  }, [styleArray]);

  function replaceVariables(itemContent, variableReplaceData) {
    // Create a map to track the visibility status of tables
    const tableVisibility = {};

    // Iterate through each table name in variableReplaceData
    for (const tableName in variableReplaceData) {
      const tableData = variableReplaceData[tableName];

      // Initialize the visibility status for the table
      tableVisibility[tableName] = {
        shouldHide: true, // Assume the table should be hidden
        rowsToAdd: "", // Store rows to add later
      };

      // Check if the table has data
      if (Array.isArray(tableData) && tableData.length > 0) {
        // The table has data, so we should process it to show the table
        tableVisibility[tableName].shouldHide = false; // Set to show the table
      } else {
        // If there's no data, skip further processing for this table
        continue; // Skip to the next table in variableReplaceData
      }

      // Find the first <tbody> and get the content inside it
      const tbodyRegex = new RegExp(/<tbody[^>]*>(.*?)<\/tbody>/s);
      let tbodyMatch = tbodyRegex.exec(itemContent);
      let firstRowStructure = null;

      if (tbodyMatch) {
        const tbodyContent = tbodyMatch[1]; // Content inside the first <tbody>
        const trRegex = /<tr>(.*?)<\/tr>/g;
        const firstRowMatch = trRegex.exec(tbodyContent);
        if (firstRowMatch) {
          firstRowStructure = firstRowMatch[1]; // The first <tr> content within <tbody>
        }
      }

      // If no <tbody> is found, fallback to searching for the first <tr> with <td> directly
      if (!firstRowStructure) {
        const trRegex = /<tr>(.*?)<\/tr>/g;
        const firstRowMatch = trRegex.exec(itemContent);
        if (firstRowMatch) {
          firstRowStructure = firstRowMatch[1]; // The first <tr> content
        }
      }

      // If we found a <tr> structure
      if (firstRowStructure) {
        let appendedRowIndexes = []; // Track which row indexes have been appended
        let firstRowAppended = false; // Track if the first row has already been appended

        // Process replacements for each object in tableData
        for (const obj of tableData) {
          let shouldAppend = false;

          // Check if the first row (or <td>) contains placeholders matching the table name
          const tdRegex = /<td[^>]*>(.*?)<\/td>/g;
          let tdMatch;
          while ((tdMatch = tdRegex.exec(firstRowStructure)) !== null) {
            const tdContent = tdMatch[1];

            // Check if any placeholder in this <td> matches the keys in the current object
            for (const key in obj) {
              if (tdContent.includes(key)) {
                shouldAppend = true; // If it contains a relevant placeholder, mark for appending
                break;
              }
            }
            if (shouldAppend) break; // No need to check further once a match is found
          }

          // If there's a match, process the row and append it
          if (shouldAppend) {
            let rowContent = firstRowStructure;

            // Replace placeholders in each <td> with corresponding values from the current object
            rowContent = rowContent.replace(tdRegex, (match, p1) => {
              for (const key in obj) {
                if (p1.includes(key)) {
                  return match.replace(key, obj[key]); // Replace within <td> tag
                }
              }
              return match; // Return unchanged if no matching key is found
            });

            // Add the updated row content to the rowsToAdd variable
            tableVisibility[tableName].rowsToAdd += `<tr>${rowContent}</tr>`;
            appendedRowIndexes.push(obj); // Mark this row as appended

            // Mark the first row as appended after the first match
            if (!firstRowAppended) {
              firstRowAppended = true; // If we append a row, mark that the first row has been processed
            }
          }
        }

        // Remove the original first <tr> inside <tbody> if a row was appended
        if (firstRowAppended && tbodyMatch) {
          // Remove the first <tr> inside <tbody> only
          const firstTrRegex = /<tr>(.*?)<\/tr>/;
          itemContent = itemContent.replace(tbodyMatch[0], (match) => {
            return match.replace(firstTrRegex, ""); // Remove only the first <tr> inside <tbody>
          });
        }

        // Append the rows before the closing </table> tag, if rows were added
        if (tableVisibility[tableName].rowsToAdd) {
          itemContent = itemContent.replace(
            "</table>",
            tableVisibility[tableName].rowsToAdd + "</table>"
          );
        }
      }

      // Replace any remaining placeholders in the entire content outside of the table
      for (const obj of tableData) {
        for (const key in obj) {
          const regex = new RegExp(key, "g");
          itemContent = itemContent.replace(regex, obj[key]);
        }
      }
    }

    // After processing all tables, modify the HTML to hide/show tables
    for (const tableName in tableVisibility) {
      if (tableVisibility[tableName].shouldHide) {
        // Hide the table by commenting it out or another means
        const hideTableRegex = new RegExp(
          `<table[^>]*id="${tableName}"[^>]*>.*?<\\/table>`,
          "s"
        );
        itemContent = itemContent.replace(
          hideTableRegex,
          "<!-- Table Hidden -->"
        );
      }
    }
    return itemContent;
  }

  const [printData, setPrintData] = useState("");

  const renderDroppedItems = () => {
    return droppedItems.length > 0 &&
      droppedItems.some((item) => item.items.length > 0)
      ? droppedItems.map((item, index) => {
          if (item.items.length > 0) {
            const itemContent = item.textContents;
            let updatedData = itemContent;
            if (variableReplaceData && variableReplaceData != "") {
              updatedData = replaceVariables(itemContent, variableReplaceData);
              // const data = replaceVariables(itemContent, variableReplaceData)
              // updatedData = replacePlaceholders(data, variableReplaceData)
              // generatePDF(updatedData)
              const placeholderRegex =
                /<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/;
              if (placeholderRegex.test(updatedData)) {
                return null; // If placeholders are still present, hide the div by returning null
              }
            }
            return (
              <div
              // style={{
              //     transformOrigin: "5% 5%",
              //     transform: "scale(0.5, 0.5)",
              //     width: '100%',
              //     overflow: 'hidden',
              // }}
              >
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: updatedData }}
                />
              </div>
            );
          }
          return null;
        })
      : null;
  };

  useEffect(() => {
    if (droppedItems.length > 0) {
      droppedItems.forEach((item) => {
        if (item.items.length > 0) {
          const itemContent = item.textContents;
          let updatedData = null;

          if (
            variableReplaceData &&
            Object.keys(variableReplaceData).length > 0
          ) {
            updatedData = replaceVariables(itemContent, variableReplaceData);
          }
          if (updatedData) {
            const placeholderRegex =
              /<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/;
            if (placeholderRegex.test(updatedData)) {
              return null; // If placeholders are still present, hide the div by returning null
            }
            setPrintData((prevValues) => prevValues + updatedData);
          }
          // else {
          //     setPrintData(prevValues => prevValues + itemContent);
          // }
        }
      });
    }
  }, [droppedItems, variableReplaceData]);

  const generatePDF = async () => {
    const element = printData; //document.getElementById('pdf-content');
    const testData = document.getElementById("pdf-content");

    const element1 = `
        <div style="width: 100mm; margin: 0 auto; overflow: hidden;">
            <div style="padding: 0mm; box-sizing: border-box;">
                <div
                    style="
                        transform-origin: 5% 5%; 
                        transform: scale(1, 1); 
                        width: 200%; /* Adjust based on content size */
                        overflow: hidden;
                    "
                >
                    ${printData}
                </div>
            </div>
            <style>
                ${`
                    @media screen {
                        * {
                            color-adjust: exact !important;
                            -webkit-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                        }
                    }
                    @media print {
                        * {
                            color-adjust: exact !important;
                            -webkit-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                        }
                    }
                `}
            </style>
        </div>
    `;

    if (!element) {
      console.log('Element with id "pdf_content" not found.');
      return;
    }
    const contentHeight = testData.scrollHeight;

    const content1 = styleArray;
    // const content1 = convertHtmlToJson(content);
    console.log("content1", content1);

    const pageWidth = content1[0]?.style[`page-width`] || 210;

    const opt = {
      margin: 0,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4 },
      jsPDF: {
        unit: "mm",
        format: [pageWidth, contentHeight / 2.75],
        orientation: "portrait",
      },
    };

    try {
      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .output("datauristring");

      const pdfBase64 = pdfDataUri.split(",")[1];

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(pdfBase64);
      } else {
        const byteCharacters = atob(pdfBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });

        const pdfUrl = URL.createObjectURL(blob);
        const newWindow = window.open(
          pdfUrl,
          "_blank",
          "width=1000px,height=1000px"
        );

        if (newWindow) {
          props.setShowBillPrint(false);

          newWindow.onload = function () {
            newWindow.print();
            newWindow.onafterprint = function () {
              newWindow.close();
            };
          };
        } else {
          console.log("Failed to open new window for printing.");
        }
      }
    } catch (error) {
      console.log("Error generating PDF:", error);
    }
  };

  useEffect(() => {
    if (droppedItems.length > 0) {
      const timer = setTimeout(() => {
        generatePDF();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [droppedItems, printData]);

  return (
    <>
      {/* <button onClick={generatePDF}>Print</button> */}
      {/* <div id="pdf-content">
                <div style={{
                    transformOrigin: "5% 5%", 
                    transform: "scale(0.7, 0.7)"
                }}>
                    {renderDroppedItems()}
                </div>
            </div > */}

      <div
        id="pdf-content"
        style={{ width: "100mm", margin: "5 auto", overflow: "hidden" }}
      >
        <div style={{ padding: "5mm", boxSizing: "border-box" }}>
          <div
            style={{
              transformOrigin: "5% 5%",
              transform: "scale(0.5, 0.5)",
              width: "100%",
              overflow: "hidden",
            }}
          >
            {renderDroppedItems()}
          </div>
        </div>
        <style>
          {`
                @media screen {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                @media print {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                `}
        </style>
      </div>
      {loading && <Loader1 />}
    </>
  );
};

export default ReportDesignForA4;
